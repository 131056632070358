import { createRouter, createWebHistory } from 'vue-router'
import breadcrumbs from '../components/templates/Vbreadcrumb.vue'

const routes = [
  {
    path: '/auth/:token?',
    name: 'auth',
    component: () => import('../views/dm/vAuth.vue'), //multiple add S
    params:true
  },

  //*****************************DASHBOARD************************* */

  {
    path: '/',
    name: 'dashboard',
    components: {
      default : () => import('../views/dm/vDashboard.vue'),
      breadcrumb : breadcrumbs
    },
    props: {
      default: {titlePage : 'Dashboard', accept : ['Admin','Tim Borang','Admin LPM']},
      breadcrumb :{ title: 'Dashboard', breadcrumbs: ['Dashboard'] },
    }
  },
  {
    path: '/dashboard/lpm',
    name: 'dashboardLpm',
    components: {
      default : () => import('../views/dm/dashboard/vDashboardLpm.vue'),
      breadcrumb : breadcrumbs
    },
    props: {
      default: {titlePage : 'Dashboard', accept : ['Admin LPM','Admin']},
      breadcrumb :{ title: 'Dashboard', breadcrumbs: ['Dashboard'] },
    }
  },

  //*****************************DASHBOARD END************************* */

  //*****************************MASTER PERIODE************************* */
  {
    path: '/master/periode',
    name: 'periode',
    components : {
      default:  () => import('../views/dm/master/periode/vPeriode.vue'),
      breadcrumb : breadcrumbs
    },
    props: {
      default: {titlePage : 'Borang', accept : ['Admin','Tim Borang','Admin LPM']},
      breadcrumb :{ title: 'Borang', breadcrumbs: ['Master','Borang'] },
    },
  },
  //*****************************MASTER INSTRUMEN************************* */
  {
    path: '/master/instrument',
    name: 'instrument',
    components : {
      default:  () => import('../views/dm/master/instrument/vInstrument.vue'),
      breadcrumb : breadcrumbs
    },
    props: {
      default: {titlePage : 'Instrumen', accept : ['Admin','Admin LPM'] },
      breadcrumb :{ title: 'Instrumen', breadcrumbs: ['Master','Instrumen'] }
    }
  },

  //*****************************MASTER TEMPLATE************************* */
  {
    path: '/master/template',
    name: 'template',
    components : {
      default:  () => import('../views/dm/master/template/vTemplate.vue'),
      breadcrumb : breadcrumbs
    },
    props: {
      default: {titlePage : 'Template', accept : ['Admin','Admin LPM'] },
      breadcrumb :{ title: 'Template', breadcrumbs: ['Master','Template'] }
    }
  },

  //*****************************BORANG PERIODE************************* */
  {
    path: '/borang/periode',
    name: 'borangPeriode',
    components : {
      default:  () => import('../views/dm/borang/periode/vPeriode.vue'),
      breadcrumb : breadcrumbs
    },
    props: {
      default: {titlePage : 'Borang', accept : ['Tim Borang'] },
      breadcrumb :{ title: 'Borang', breadcrumbs: ['Borang'] }
    }
  },
  //*****************************BORANG HANDLING************************* */
  {
    path: '/borang/manage/:id',
    name: 'borangManage',
    components : {
      default:  () => import('../views/dm/borang/manage/vManage.vue'),
      breadcrumb : breadcrumbs
    },
    props: {
      default: {titlePage : 'Kelola Borang', accept : ['Tim Borang'] },
      breadcrumb :{ title: 'Kelola Borang', breadcrumbs: ['Borang'] }
    }
  },
  {
    path: '/borang/manage/preview/:periode_id?',
    name: 'borangManagePreview',
    components : {
      preview:  () => import('../views/dm/borang/manage/vPreview.vue'),
    },
    props : {
      preview : { accept : ['Tim Borang','Admin LPM']}
    },
    params:false
  },

  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
