

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

//datepicker
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

//icon
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();


//css template
import '/public/assets/css/argon-dashboard.css';
import '/public/assets/css/dataTables.bootstrap5.min.css';

//js template
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables.min.js';
import '/public/assets/js/dataTables.bootstrap5.min.js';

// import '/public/assets/js/core/popper.min.js'
// import '/public/assets/js/core/bootstrap.min.js'

import '/public/assets/js/plugins/perfect-scrollbar.min.js'
import '/public/assets/js/argon-dashboard.js'

// import "bootstrap/dist/js/bootstrap.js"

//vuex
import store from './store'

//vue-select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

//Tree View
import 'he-tree-vue/dist/he-tree-vue.css' // base style

//import tinymce
// import tinymce from 'vue-tinymce-editor'


import CKEditor from '@ckeditor/ckeditor5-vue';
CKEditor.editorConfig = function( config ) {
    // Define changes to default configuration here.
    // For complete reference see:
    // http://docs.ckeditor.com/#!/api/CKEDITOR.config

    // The toolbar groups arrangement, optimized for a single toolbar row.
    config.toolbarGroups = [
        { name: 'document',    groups: [ 'Source', 'mode', 'document', 'doctools' ] },
        { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
        { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
        { name: 'forms' },
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
        { name: 'links' },
        { name: 'insert' },
        { name: 'styles' },
        { name: 'colors' },
        { name: 'tools' },
        { name: 'others' }
    ];

    // The default plugins included in the basic setup define some buttons that
    // are not needed in a basic editor. They are removed here.
    config.removeButtons = 'Cut,Copy,Paste,Undo,Redo,Underline,Strike,Subscript,Superscript';

    // Dialog windows are also simplified.
    config.removeDialogTabs = 'link:advanced';
};

//import quillwyswyg
// import { QuillEditor } from '@vueup/vue-quill'
// import '@vueup/vue-quill/dist/vue-quill.snow.css';

const app = createApp(App)

app.component("fa", FontAwesomeIcon);
app.component('v-select', vSelect);
app.component('Datepicker', Datepicker);
// app.component('QuillEditor', QuillEditor)

// app.component('tinymce', tinymce)


app.use(CKEditor);
app.use(router);
app.use(store);



app.mount('#app');



//axios global config
import axios from 'axios'

const ax = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    },
});
ax.interceptors.request.use((config) => {

});

axios.interceptors.request.use(function (config) {

    //display loading before request
    store.commit('loading',true);

    //add default param
    config.params = config.params || {};
    // config.params['uid'] = store.state.userdata.id;
    return config;

}, function (error) {
    // Do something with request error
    return Promise.reject(error);   
});

axios.interceptors.response.use(
    function (response) {
        store.commit('loading',false);
        // console.log(response);
        return response;
    },
    function(error) {

        //stop loading
        store.commit('loading',false);
        var msg='';

        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);

            //error code handle
            switch(error.response.status){
                case 422://validation
                    msg+='<ul>';
                    for(var key in error.response.data.errors) {
                        for(var subkey in error.response.data.errors[key]) {
                            msg+='<li>'+error.response.data.errors[key][subkey]+'</li>';
                        }
                    }
                    msg+='</ul>';
                break;
                case 404://not found
                     msg = 'Layanan tidak ditemukan';
                break;
                default://500
                     msg = 'Kesalahan Server';
                break;
            }

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }

        
        store.commit('noty',{
            type : 'danger',
            msg : msg,
        });

    }
);
