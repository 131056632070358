import { createStore } from 'vuex'

export default createStore({
  state: {
    email:"",
    api_url:"https://djl.iainkendari.ac.id/api/dm/",
    sso_url:"https://portal.iainkendari.ac.id",
    userdata : {},
    form : {
      dropdown : {
        active : ['Ya','Tidak'],
        semester : ['Ganjil','Genap'],
        template : [],
        buktiId : [],
        access_level : ['Ketua','Anggota'],
        file_source : [
          {'label':'Upload','code':'Upload'},
          {'label':'Link','code':'Link'}
        ],
      }
    },
    noty : {
      bg : "bg-gradient-info",
      msg : "",
      title : "",
      type : "info",
      display : false,
      counter : 1,
      icon : "fa-bell"
    },
    loading:false,
    style : {
      btnAdd : "btn btn-icon btn-outline-white ",
      btnPrimary : "btn btn-icon btn-outline-primary",
      btnSecondary : "btn btn-icon btn-outline-secondary",
      btnDanger : "btn btn-icon btn-outline-danger",
      btnDisplay : "d-none d-lg-inline-block d-xl-inline-block d-xxl-inline-block",
      trActiveClass : "table-dark",
    },
    template : {
      darkmode : false,
      preview : false,
      firstLogin : false,
    }
  },
  getters: {

  },
  mutations: {
    setGroupActive(state, data){
      state.userdata.group_active = data;
      //override userdata
      const ls = JSON.parse(localStorage.getItem('sambo'));
      ls.group_active = data;
      localStorage.setItem('sambo', JSON.stringify(ls)); 
    },
    setDropdownTemplate(state, data){
      // data.push({null:'Pilih Template'});

      state.form.dropdown.template = data;
    },
    setDropdownAll(state,data){
      state.form.dropdown.template = data.drpTemplate;
      state.form.dropdown.buktiId = data.drpBukti;
    },
    login (state, datalogin) {
      state.userdata = datalogin;
      localStorage.setItem('sambo', JSON.stringify(datalogin)); //convert to string
    },
    logout(state){
        state.userdata ="";
        localStorage.removeItem('sambo');
        // alert('Logged out successed');
        window.location.href=state.sso_url;
    },
    //checking session
    initialiseStore(state) {
      let sambo = JSON.parse(localStorage.getItem('sambo'));
      if(!sambo){
        alert('Session expired, please login again');
        window.location.href=state.sso_url;
      }else{
        state.userdata = sambo;
      }
    },
    //setup loading
    loading(state,show){
      state.loading = show;
    },
    setTemplatePreview(state, option){
      state.template.preview = option;
    },
    setTemplateFirstLogin(state, option){
      state.template.firstLogin = option;
    },
    //setup notification
    noty(state,conf){
      // console.log(conf);
      switch(conf.type){
        case 'success':
          state.noty.bg = "bg-gradient-success";
          state.noty.title = "Berhasil";
          state.noty.icon = "fa-check";
          
        break;
        case 'info':
          state.noty.bg = "bg-gradient-info";
          state.noty.title = "Informasi";
          state.noty.icon = "fa-bell";

        break;
        case 'warning':
          state.noty.bg = "bg-gradient-warning";
          state.noty.title = "Peringatan";
          state.noty.icon = "fa-exclamation-triangle";
        break;
        default://danger
          state.noty.bg = "bg-gradient-danger";
          state.noty.title = "Gagal";
          state.noty.icon = "fa-circle-exclamation";
        break;
      }
      state.noty.msg = conf.msg;
      state.noty.display = true;
      state.noty.counter = Math.random();
    }
  },
  actions: {

  },
  modules: {

  }
})