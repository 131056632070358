<template>
    <div>
        <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 ps-2 me-sm-6 me-5">
            <li class="breadcrumb-item text-sm text-white">
                <router-link class="text-white"  to="/"><fa icon="gauge" /> </router-link>
            </li>
            <li v-if="!breadcrumbs.length">No item found</li>
            <li  class="breadcrumb-item text-sm text-white active" v-for='menu in breadcrumbs' :key='menu'>
            {{menu}}
            </li>
        </ol>
        <h6 class="text-white font-weight-bolder ms-2">{{title}}</h6>
    </div>
</template>
<script>

    export default {
        name : 'TempBreadcrumb',
        props: {
            title: String,
            breadcrumbs: Array,
        },
    }

</script>
    