<template>

  <!--LOADDER-->
  <div class="loader" style="display: none" id="loader">
    <div class="boxes">
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="boxes-text">Proses dulu kk...</div>
  </div>

  <div class="loader" v-if="loading">
    <div class="boxes">
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="boxes-text">Proses dulu kk...</div>
  </div>
  <!--LOADDER END-->

  <!--FULLSREEN-->
  <div v-if="$store.state.template.preview">
    <router-view name="preview"></router-view>
  </div>
  <!--FULLSREEN END-->

  <!--NORMAL SCREEN-->
  <div v-else>

    <div class="min-height-300 bg-primary position-absolute w-100"></div>

    <TempHelper></TempHelper>
    <TempHeader></TempHeader>

    <main class="main-content position-relative border-radius-lg">
      <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl z-index-sticky"
        id="navbarBlur" data-scroll="false">
        <div class="container-fluid py-1 px-3">
          <nav aria-label="breadcrumb">
            <router-view name="breadcrumb"></router-view>
          </nav>
          <div class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none">
            <a href="javascript:;" class="nav-link p-0">
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </div>
          <div class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
            <div class="ms-md-auto pe-md-3 d-flex align-items-center">
              <div class="input-group">
                <span class="input-group-text text-body"><i class="fas fa-search" aria-hidden="true"></i></span>
                <input type="text" class="form-control" placeholder="Type here..." />
              </div>
            </div>
            <ul class="navbar-nav justify-content-end">
              <li class="nav-item d-flex align-items-center">
                <a v-if="$store.state.userdata" @click="modalLogoutShow" class="nav-link text-white font-weight-bold px-0 hover-pointer">
                  <i class="fa fa-power-off"></i>&nbsp;<span class="d-sm-inline d-none">Logout</span>
                </a>
              </li>
              <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                <a href="#" class="nav-link text-white p-0" id="iconNavbarSidenav">
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line bg-white"></i>
                    <i class="sidenav-toggler-line bg-white"></i>
                    <i class="sidenav-toggler-line bg-white"></i>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container-fluid py-4">
        <router-view class="view content" v-slot="{ Component }">
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </div>
      <TempFooter></TempFooter>
    </main>
  </div>
  <!--NORMAL SCREEN END-->


  <div class="modal fade" id="modalLogout" tabindex="-1" role="dialog" aria-labelledby="modal-notification" aria-hidden="true">
    <div class="modal-dialog modal-danger modal-dialog-centered modal-" role="document">
      <div class="modal-content">
        <div class="modal-body">
            <h4 class="font-weight-bolder text-gradient text-danger mt-4">Anda Yakin Keluar Aplikasi  ?</h4>
            <p class="mb-0">Pastikan data anda sudah tersimpan</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="logout">Iye</button>
          <button type="button" class="btn btn-link ml-auto" data-bs-dismiss="modal">Nda Jadi</button>
        </div>
      </div>
    </div>
  </div>

  <!--NOTIFICATION-->
  <div class="position-fixed top-1 start-50 translate-middle-x" style="z-index: 99999">
    <div class="toast fade mt-2" :class="[noty.bg]" role="alert" aria-live="assertive" id="notyToast" aria-atomic="true"
      data-bs-autohide="true" data-bs-delay="5000">
      <div class="toast-header bg-transparent border-0">
        <i class="fa text-white me-2" :class="[noty.icon]"></i>
        <span class="me-auto text-white font-weight-bold">{{
          noty.title
        }}</span>
        <small class="text-white"></small>
        <i class="fas fa-times text-md text-white ms-3 cursor-pointer" data-bs-dismiss="toast" aria-label="Close"
          aria-hidden="true"></i>
      </div>
      <hr class="horizontal light m-0" />
      <div class="toast-body text-white" v-html="noty.msg"></div>
    </div>
  </div>



</template>
<script>
import TempHeader from "@/components/templates/Vheader.vue";
import TempFooter from "@/components/templates/Vfooter.vue";
import TempHelper from "@/components/templates/Vhelper.vue";

window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");
// import { useRoute, useRouter } from "vue-router";
// const router = useRouter();
// const route = useRoute();

import axios from "axios";
import $ from "jquery";
import { ref } from "vue";


var toast;

export default {
  components: {
    TempHeader,
    TempFooter,
    TempHelper,
  },
  setup() {
    const modalLogout = ref(null);
    return { modalLogout };
  },
  data: function () {
    return {
      notyDisplay: false,
      notyCounter: 1,
      routeName: "",
    };
  },
  mounted() {
    const app = this;

    //modal logout
    this.modalLogout = new bootstrap.Modal(
      document.getElementById("modalLogout"),
      {
        keyboard: false,
        focus: false,
        backdrop: "static",
      }
    );

    //setup toast
    toast = bootstrap.Toast.getOrCreateInstance(
      document.getElementById("notyToast")
    );

    $(document).on("click", "table.dataTable.crud td", function () {
      const obj = $(this).parent();
      if (obj.children("td").length > 1) {
        if (obj.hasClass(app.$store.state.style.trActiveClass)) {
          obj.removeClass(app.$store.state.style.trActiveClass);
        } else {
          if (!obj.closest("table").hasClass("multiple")) {
            $("table.dataTable tr").removeClass(
              app.$store.state.style.trActiveClass
            );
          }
          obj.addClass(app.$store.state.style.trActiveClass);
        }
        app.dtToggleActive();
      }
    });

    //load all dropdown first time
    app.loadDropdownAll();
    // app.setupAccess();
  },
  methods: {
    setupAccess() {
      const groupAccept = ["Admin", "Admin LPM", "Tim Borang"];
      const groups = this.$store.state.userdata.groups;
      // console.log(groups);

      groups.forEach(function (group, key) {
        if (!groupAccept.includes(group)) {
          //remove group yang tidak diizinkan
          delete groups[key];
          // groups.splice(key, 1);

          // decoded.groups.filter(e => e !== group)
        }
      });
      // console.log(groups);
    },
    setupGroupActive(){
      if(this.$store.state.userdata.group_active == 'Tim Borang'){
          this.$router.push({ name: 'dashboard' }); //go to dashboard first
      }else{
          this.$router.push({ name: 'dashboardLpm' }); //go to dashboard first
      }
    },
    logout() {
      this.$store.commit("logout");
    },
    notyShow() {
      toast.show();
    },
    notyHide() {
      toast.hide();
    },
    modalLogoutShow(){
        this.modalLogout.show();
    },
    modalLogoutClose(){
        this.modalLogout.hide();
    },
    dtGetId() {
      const app = this;
      const id = [];
      $("table.dataTable.crud tr." + app.$store.state.style.trActiveClass).each(
        function () {
          if ($(this).children("td").length > 0) id.push($(this).attr("id"));
        }
      );
      return id;
    },
    dtToggleActive() {
      const app = this;
      const id = app.dtGetId();
      if (id.length > 1) {
        $(
          "button.detail,button.edit,button.update,button.upload,button.activate,button.deactivate,button.verification,button.btn-comments"
        ).attr("disabled", "disabled");
        $("button.delete,a.delete,a.delete-file").removeAttr("disabled");
        $(
          "a.cetak-tanda-terima,a.cetak-serah-terima,a.cetak-detail,a.delete,a.delete-file"
        ).attr("disabled", "disabled");
      } else if (id.length == 1) {
        $(
          "button.delete,button.detail,button.edit,button.update,button.upload,button.activate,button.deactivate,button.verification,button.btn-comments,a.delete,a.delete-file"
        ).removeAttr("disabled");
        $(
          "a.cetak-tanda-terima,a.cetak-serah-terima,a.cetak-detail,a.delete,a.delete-file"
        )
          .removeAttr("disabled")
          .attr("data-id", app.dtGetId());
      } else {
        $(
          "button.delete,button.detail,button.edit,button.update,button.upload,button.activate,button.deactivate,button.verification,button.btn-comments,a.delete,a.delete-file"
        ).attr("disabled", true);
        $(
          "a.cetak-tanda-terima,a.cetak-serah-terima,a.cetak-detail,a.delete,a.delete-file"
        ).attr("disabled", "disabled");
      }
    },
    loadDropdownTemplate() {
      //load template dropdown
      axios
        .get(this.$store.state.api_url + "master/dropdown/template", {})
        .then((res) => {
          if (res.data.success) {
            this.$store.commit("setDropdownTemplate", res.data.res);
          }
        });
    },
    loadDropdownAll() {
      axios
        .get(this.$store.state.api_url + "master/dropdown/all", {})
        .then((res) => {
          if (res.data.success) {
            this.$store.commit("setDropdownAll", res.data.res);
          }
        });
    },
  },
  created: function () { },
  computed: {
    noty() {
      this.notyCounter = this.$store.state.noty.counter;
      return this.$store.state.noty;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  watch: {
    notyCounter(newVal, oldVal) {
      if (this.$store.state.noty.display) {
        this.notyShow();
      } else {
        this.notyHide();
      }
    },
    $route(to, from, tes) {
      //detect route change
      console.log(from);
      console.log(to);
      // console.log(this.$store.state.userdata.group_active);

      //group accept from router
      let acceptGroups = [];
      if (to.matched[0].props.hasOwnProperty("default")) {
        //default content
        acceptGroups = to.matched[0].props.default.accept;
        this.$store.commit("setTemplatePreview", false);
      } else {
        if (to.matched[0].props.hasOwnProperty("preview")) {
          //fullscreen content
          acceptGroups = to.matched[0].props.preview.accept;
          this.$store.commit("setTemplatePreview", true);
        }
      }

      //check login & access group
      const allow = ['auth','borangManagePreview']; //route without login
      if (!allow.includes(to.name)) {

        this.$store.commit("initialiseStore");
        console.log(acceptGroups);
        console.log(this.$store.state.userdata.group_active);
        if (!acceptGroups.includes(this.$store.state.userdata.group_active)) {
          console.log('from app');
          alert("Anda tidak memiliki hak akses ke halaman ini");
          // this.$router.push({ name: from.name });
          this.setupGroupActive();
        }
      }
    },
  },
};
</script>