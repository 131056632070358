<template>
    <div>

        <!-- ADMIN -->
        <ul class="navbar-nav" v-if="$store.state.userdata.group_active==='Admin'">
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="gauge" />
                    </div>
                    <span class="nav-link-text ms-1">Dashboard</span>
                </router-link>
            </li>
            <li class="nav-item mt-3">
                <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">MASTER</h6>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/master/periode">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="calendar" />
                    </div>
                    <span class="nav-link-text ms-1">Borang</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/master/template">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="clipboard" />
                    </div>
                    <span class="nav-link-text ms-1">Template</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/master/instrument">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="folder-tree" />
                    </div>
                    <span class="nav-link-text ms-1">Instrumen</span>
                </router-link>
            </li>
        </ul>
        <!-- ADMIN END -->

        <!-- ADMIN LPM-->
        <ul class="navbar-nav" v-if="$store.state.userdata.group_active==='Admin LPM'">
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/dashboard/lpm">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="gauge" />
                    </div>
                    <span class="nav-link-text ms-1">Dashboard</span>
                </router-link>
            </li>
            <li class="nav-item mt-3">
                <h6 class="ps-4  ms-2 text-uppercase text-xs font-weight-bolder opacity-6">MASTER</h6>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/master/periode">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="calendar" />
                    </div>
                    <span class="nav-link-text ms-1">Borang</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/master/template">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="clipboard" />
                    </div>
                    <span class="nav-link-text ms-1">Template</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/master/instrument">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="folder-tree" />
                    </div>
                    <span class="nav-link-text ms-1">Instrumen</span>
                </router-link>
            </li>
        </ul>
        <!-- ADMIN LPM END -->


        <!-- TIM BORANG -->
        <ul class="navbar-nav" v-if="$store.state.userdata.group_active==='Tim Borang'">
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="gauge" />
                    </div>
                    <span class="nav-link-text ms-1">Dashboard</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link class="nav-link" active-class="active" to="/borang/periode">
                    <div class="icon icon-shape icon-sm text-center  me-2 d-flex align-items-center justify-content-center">
                        <fa icon="book" />
                    </div>
                    <span class="nav-link-text ms-1">Borang</span>
                </router-link>
            </li>
        </ul>
        <!-- TIM BORANG -->

    </div>
</template>

<script>
export default {
  name: 'TempMenu',
  props: {
    msg: String
  }
}
</script>