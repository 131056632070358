<template>
    <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4 " id="sidenav-main" style="z-index:1039">
        <div class="sidenav-header">
            <span  id="iconSidenav" class="cursor-pointer"><i class="fa-solid fa-xmark p-3  text-secondary opacity-5 position-absolute end-0 top-0  d-xl-none" ></i></span>
        <a class="navbar-brand m-0" href=" https://demos.creative-tim.com/argon-dashboard-pro/pages/dashboards/default.html " target="_blank">
            <img src="/assets/img/simultan_logo.png" class="navbar-brand-img h-100" alt="main_logo">
            <span class="ms-1 font-weight-bold lead " style="color:#5e72e4">SIMULTAN</span>
        </a>
        </div>
        <hr class="horizontal dark mt-0">
        <div class="sidenav-footerx mx-3 my-3">
            <div class="dropdown">
                <button class="btn btn-sm w-100 bg-gradient-info dropdown-toggle " type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                {{ $store.state.userdata.group_active }}
                </button>
                <ul class="dropdown-menu mt-0" style="top:0px !important" aria-labelledby="dropdownMenuButton" >
                    <li v-for="group in $store.state.userdata.groups" v-bind:key="group" ><a class="dropdown-item active" href="#" @click="setGroupActive(group)">{{ group }}</a></li>
                </ul>
            </div>
        </div>
        <div class="collapse navbar-collapse  w-auto h-auto" id="sidenav-collapse-main">
            <TempMenu></TempMenu>
        </div>
    </aside>
</template>

<script setup>
import TempMenu from "@/components/templates/Vmenu.vue" 
</script>
<script>
export default {
  name: 'TempHeader',
  props: {
    msg: String
  },
  methods:{
    setGroupActive(group){
        if(confirm("Apakah anda yakin ingin login sebagai "+group+" ?")){
            this.$store.commit('setGroupActive',group);
            this.$root.setupGroupActive();//app.vue
        }
    }
  }
}
</script>